.modal{
    flex-basis: 30%;
    width: 35%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    border: 2px solid black;
    border-radius: 10px;
    padding: 50px;
    z-index: 2;
    background-color: #ddd;
    transition: .5s
}

.box{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
}

.modal h1, .modal p{
    color: #4f1e1e;
}