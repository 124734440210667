.templates{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

/*.templates + a.btn-links{*/
/*    !*width:300px;*!*/
/*    display:block;*/
/*    margin: 30px auto;*/
/*}*/