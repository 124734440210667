* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #423434;
  font-family: "arial";
  color: white;
}