.header{
    background-color: #000;
    text-align: center;
    text-transform: capitalize;
    box-shadow: 0 0 5px 5px white;

}


.header h1 {
    padding: 40px;
    text-shadow: 1px 1px white;
    text-transform: uppercase;
}