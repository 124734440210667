
.template{
    display: flex;
    flex-direction: column;
    margin:50px 20px;
    padding:20px;
    min-width:300px;
    border: 4px solid black;
    box-shadow: 0 0 4px 4px white;
    /*background-color: red;*/
}

.template-name{
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: rgb(57, 229, 229);
}

.template-info{
    margin:10px 5px;
}

.template-first-paragraph, .template-last-paragraph{
    margin: 5px 5px 25px 5px;
    padding: 10px;
    border: 1px solid white;
    font-style: italic;
}